import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Navbar, Nav, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [instances, setInstances] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState('');
  const [statusMessages, setStatusMessages] = useState({});
  const [loadingMessage, setLoadingMessage] = useState('');  // State to hold the randomly selected loading message
  const [loadingPercentage, setLoadingPercentage] = useState(0); // State to track the loading percentage

  // List of random messages
  const loadingMessages = [
    "Spinning up the...spinner.",
    "Poking the cyber-gremlins...",
    "Turning it off and on again...",
    "Placing hamsters on wheels...",
    "Refilling the glitter pouches...",
    "Getting some more coffee..."
  ];

  useEffect(() => {
    // Select a random loading message when the app starts loading
    setLoadingMessage(loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);

    // Simulate loading percentage
    const interval = setInterval(() => {
      setLoadingPercentage(prev => {
        if (prev < 100) {
          return prev + Math.floor(Math.random() * 2) + 1; // Increment by either 1 or 2
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 95); // Update percentage every 95ms

    return () => clearInterval(interval);
  }, []);

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/login`;
  };

  const handleLogout = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/logout`;
  };

  const handleInstanceAction = (instanceId, action, state, status) => {
    let message = '';

    // Determine if action is allowed based on the current state and status
    if (action === 'start') {
      if (state === 'running') {
        message = "Can't start workstation when it is already running...";
      } else if (state === 'rebooting') {
        message = "Can't start workstation when it is rebooting...";
      } else if (state === 'shutting-down') {
        message = "Can't start workstation when it is shutting down...";
      } else if (state === 'stopping') {
        message = "Can't start workstation when it is stopping...";
      }
    } else if (action === 'stop' || action === 'reboot') {
      if (!(state === 'running' && status === 'good to go')) {
        message = `Can only ${action} workstation if it is running and good to go...`;
      }
    }

    if (message) {
      // Display the error message
      setStatusMessages(prevMessages => ({ ...prevMessages, [instanceId]: message }));
      setTimeout(() => {
        setStatusMessages(prevMessages => ({ ...prevMessages, [instanceId]: '' }));
      }, 5000);
      return;
    }

    // Proceed with action if valid
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/instance-action`, { instanceId, action })
      .then(response => {
        message = `Workstation received ${action.charAt(0).toUpperCase() + action.slice(1)} request...`;
        setStatusMessages(prevMessages => ({ ...prevMessages, [instanceId]: message }));
        setTimeout(() => {
          setStatusMessages(prevMessages => ({ ...prevMessages, [instanceId]: '' }));
        }, 5000);
      })
      .catch(error => {
        message = `Error performing ${action} on workstation...`;
        setStatusMessages(prevMessages => ({ ...prevMessages, [instanceId]: message }));
        setTimeout(() => {
          setStatusMessages(prevMessages => ({ ...prevMessages, [instanceId]: '' }));
        }, 5000);
      });
  };

  const fetchInstances = () => {
    if (loggedIn) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/instances`)
        .then(response => {
          setInstances(response.data);
          setLoading(false);
        })
        .catch(error => {
          setError("Oop...Can't fetch instances. Give it a few minutes and reload the page. Please shout if there is no joy.");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Check if the user is logged in
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user`)
      .then(response => {
        if (response.data.loggedIn) {
          setLoggedIn(true);
          setUserName(response.data.user.name);  // Set the user's name
          fetchInstances();  // Fetch instances after confirming login
        } else {
          setLoggedIn(false);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoggedIn(false);
        setLoading(false);
      });
  }, []);

  // Refresh instances every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(fetchInstances, 5000);
    return () => clearInterval(intervalId);  // Cleanup the interval on component unmount
  }, [loggedIn]);

  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#343a40' }}>
        <p className="text-light mb-3">{loadingMessage}</p>  {/* Display the loading message */}
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="text-light mt-3">{loadingPercentage}%</p>  {/* Display the loading percentage */}
      </div>
    );
  }

  const getCardBorderColor = (state, status) => {
    if (state === 'stopped') return 'secondary';
    //DUPLICATE BAK# if (state === 'running' && status === 'good to go') return 'success';
    if (state === 'running') return 'success';
    return 'warning';
  };

  return (
    <div className="bg-dark text-light" style={{ minHeight: '100vh' }}>
      <Navbar style={{ backgroundColor: '#343a40' }} variant="dark" expand="lg">
        <Container>
          {loggedIn && (
            <Navbar.Brand>Rake FX Box Manager</Navbar.Brand>
          )}
          <Nav className="ms-auto">
            {loggedIn ? (
              <Button variant="outline-light" onClick={handleLogout}>Logout</Button>
            ) : (
              <Button variant="outline-light" onClick={handleLogin}>Login with Google</Button>
            )}
          </Nav>
        </Container>
      </Navbar>

      <Container className="py-4">
        {error && <div>{error}</div>}

        {loggedIn && instances ? (
          <>
            <h1>{userName}'s Workstations</h1>  {/* Dynamic Heading with User's Name */}
            <Row xs={1} md={2} className="g-4" style={{ maxWidth: '900px', margin: '0 auto' }}>
              {instances.map((instance, idx) => {
                const nameTag = instance.Tags.find(tag => tag.Key === 'Name')?.Value || 'Unnamed Instance';
                const statusCheck = instance.StatusCheck || 'N/A';  // Assuming status check info is included in instance data

                return (
                  <Col key={idx} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card bg="light" text="dark" border={getCardBorderColor(instance.State, statusCheck)} style={{ width: '30rem', borderWidth: '5px' }}>
                      <Card.Header><strong>{nameTag}</strong></Card.Header>
                      <Card.Body>
                        <Card.Title><strong>Instance ID:</strong> {instance.InstanceId}</Card.Title>
                        <Card.Text>
                          <strong>State:</strong> {instance.State}<br />
                          <strong>Status Check:</strong> {statusCheck}<br />
                          <strong>Public IP:</strong> {instance.PublicIpAddress || 'N/A'}<br />
                          <strong>Private IP:</strong> {instance.PrivateIpAddress || 'N/A'}<br />
                        </Card.Text>
                        <Button
                          variant="outline-dark"
                          onClick={() => handleInstanceAction(instance.InstanceId, 'start', instance.State, statusCheck)}
                          disabled={instance.State !== 'stopped'}
                        >
                          Start
                        </Button>{' '}
                        <Button
                          variant="outline-dark"
                          onClick={() => handleInstanceAction(instance.InstanceId, 'stop', instance.State, statusCheck)}
                          disabled={!(instance.State === 'running' && statusCheck === 'good to go')}
                        >
                          Stop
                        </Button>{' '}
                        <Button
                          variant="outline-dark"
                          onClick={() => handleInstanceAction(instance.InstanceId, 'reboot', instance.State, statusCheck)}
                          disabled={!(instance.State === 'running' && statusCheck === 'good to go')}
                        >
                          Reboot
                        </Button>
                        {statusMessages[instance.InstanceId] && (
                          <div className="mt-2 text-info">
                            {statusMessages[instance.InstanceId]}
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : (
          !loggedIn && (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '70vh' }}>
              <h1>Hi there. Hit the login to proceed!</h1>
            </div>
          )
        )}
      </Container>
    </div>
  );
}

export default App;
